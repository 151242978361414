import React, { useEffect } from 'react';

const IframeFourvenues = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://www.fourvenues.com/assets/iframe/erasmus-dreamland/calendar@";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="iframe-container" style={{
      backgroundImage: `url(${process.env.PUBLIC_URL + '/04.jpg'})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      minHeight: '100vh',
      padding: '0px 0',
      textAlign: 'center',
 
    }}>
      <h2 className="entradas">COMPRA TUS ENTRADAS</h2>
      <div id="fourvenues-iframe"></div>
    </div>
  );  
};   

export default IframeFourvenues;
