import React from 'react';
import Map from './Map';
import Slider from './Slider';

import Croatia from './CroatiaScript';
import ArtistApp from './ArtistApp'; 
import Footer from './Footer';

function Home() {
  return (
    <div>
        <Slider />
        <ArtistApp />
        <Footer />
    </div>
  );
}

export default Home;